@import "mixins";
$bannerBgColour: #1D1D27;

.id_topBannerHolder {

	.id_topBannerMainLogoHolder {
		position: relative;
		width: 100%;

		.id_topBannerMainLogo {
			width: 100%;
			height: auto;
		}

		.id_topBannerTriangleMask {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 50px;
		}
	}

	.id_topBannerPicAndNameHolder {
		width: 70%;
		margin: 0 auto;
		text-align: right;
		position: relative;

		.id_topBannerAboutPic {
			position: absolute;
			top: -110px;
			right:0;
			border-radius: 50%;
			width: 125px;
			height: auto;
			box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
		}

		h2 {
			margin: 0;
			padding-top: 20px;
		}

		.id_contactLogosHolder {
			margin-top: 4px;

			.id_contactLogos {
				width: 40px;
				height: 40px;
				position: relative;
				display: inline-block;
				margin-right: 8px;

				.id_svgLogo {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					@include transform-transition();
				}

				&:hover {
					.id_svgLogo {
						transform: scale(1.1);
					}
				}
			}
			.id_contactLogos + .id_contactLogos {
				margin-right: 0;
			}
		}
	}
}

/*
 * Responsive behaviour
*/
@media all and (max-width: 900px) {
	
	.id_topBannerHolder {
		
		.id_topBannerPicAndNameHolder {

			.id_topBannerAboutPic {
				top: -70px;
				width: 100px;
			}

			h2 {
				padding-top: 42px;
			}
		}
		
	}
}

@media all and (max-width: 800px) {
	
	.id_topBannerHolder {
		
		.id_topBannerPicAndNameHolder {

			width: 80%;
			text-align: center;

			.id_topBannerAboutPic {
				top: -65px;
				right: calc(50% - 50px);
			}
		}
		
	}
}

@media all and (max-width: 550px) {
	
	.id_topBannerHolder {
		
		.id_topBannerPicAndNameHolder {

			width: 90%;

			.id_topBannerAboutPic {
				top: -55px;
			}

			h2 {
				padding-top: 52px;
			}
		}
		
	}
}

@media all and (max-width: 440px) {
	
	.id_topBannerHolder {

		.id_topBannerMainLogoHolder {

			.id_topBannerTriangleMask {
				bottom: -16%;
				transform: skewY(5deg);
			}
		}
		
		.id_topBannerPicAndNameHolder {

			.id_topBannerAboutPic {
				top: -35px;
			}

			h2 {
				padding-top: 72px;
			}
		}
		
	}
}
@mixin box-sizing {
	-moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@mixin bg-color-transition() {
	-webkit-transition: background-color 0.2s linear;
	-moz-transition: background-color 0.2s linear;
	-o-transition: background-color 0.2s linear;
	-ms-transition: background-color 0.2s linear;
	transition: background-color 0.2s linear;
}

@mixin linkBtn-transition() {
	-webkit-transition: background-color 0.2s linear, -webkit-transform 0.2s linear;
	-moz-transition: background-color 0.2s linear, -moz-transform 0.2s linear;
	-o-transition: background-color 0.2s linear, -o-transform 0.2s linear;
	-ms-transition: background-color 0.2s linear, -ms-transform 0.2s linear;
	transition: background-color 0.2s linear, transform 0.2s linear;
}

@mixin fill-transition() {
	-webkit-transition: fill 0.2s linear;
	-moz-transition: fill 0.2s linear;
	-o-transition: fill 0.2s linear;
	-ms-transition: fill 0.2s linear;
	transition: fill 0.2s linear;
}

@mixin transform-transition() {
	-webkit-transition: -webkit-transform 0.2s linear;
	-moz-transition: -moz-transform 0.2s linear;
	-o-transition: -o-transform 0.2s linear;
	-ms-transition: -ms-transform 0.2s linear;
	transition: transform 0.2s linear;
}

@mixin opacity-transition() {
	-webkit-transition: opacity 0.2s linear;
	-moz-transition: opacity 0.2s linear;
	-o-transition: opacity 0.2s linear;
	-ms-transition: opacity 0.2s linear;
	transition: opacity 0.2s linear;
}

@mixin btn-highlight-transition() {
	-webkit-transition: opacity 0.2s linear, width 0.2s linear, height 0.2s linear;
	-moz-transition: opacity 0.2s linear, width 0.2s linear, height 0.2s linear;
	-o-transition: opacity 0.2s linear, width 0.2s linear, height 0.2s linear;
	-ms-transition: opacity 0.2s linear, width 0.2s linear, height 0.2s linear;
	transition: opacity 0.2s linear, width 0.2s linear, height 0.2s linear;
}